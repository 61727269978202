@tailwind base;
@tailwind components;
@tailwind utilities;
/* BOOK 200 */
/* REGULAR 300 */
/* LIGHT 100 */
/* HEAVY 700 */
/* MEDIUM 500 */
/* EXTRABOLD 900 */
@font-face {
    font-family: "Figerona";
    src: local("Figerona"),
     url("./assets/fonts/Figerona/Figerona.ttf") format("truetype");
    }
@font-face {
    font-family: "AliandoRocky";
    src: local("AliandoRocky"),
     url("./assets/fonts/AliandoRocky/AliandoRocky.ttf") format("truetype");
    font-weight: 300;
    }
    @font-face {
        font-family: "FuturaBT";
        src: local("FuturaBT"),
         url("./assets/fonts/FuturaBT/Futura-Book.ttf") format("truetype");
        font-weight: 200;
        }
        @font-face {
            font-family: "FuturaBT";
            src: local("FuturaBT"),
             url("./assets/fonts/FuturaBT/Futura-Heavy.ttf") format("truetype");
            font-weight: 700;
            }    @font-face {
                font-family: "FuturaBT";
                src: local("FuturaBT"),
                 url("./assets/fonts/FuturaBT/Futura-Light.ttf") format("truetype");
                font-weight: 100;
                }    @font-face {
                    font-family: "FuturaBT";
                    src: local("FuturaBT"),
                     url("./assets/fonts/FuturaBT/FuturaBold.ttf") format("truetype");
                    font-weight: bold;
                    }    @font-face {
                        font-family: "FuturaBT";
                        src: local("FuturaBT"),
                         url("./assets/fonts/FuturaBT/FuturaMedium.ttf") format("truetype");
                        font-weight: 500;
                        }
                        @font-face {
                            font-family: "WorkSans";
                            src: local("WorkSans"),
                             url("./assets/fonts/WorkSans/WorkSans-ExtraBold.ttf") format("truetype");
                            font-weight: 900;
                            }     
                            @font-face {
                                font-family: "WorkSans";
                                src: local("WorkSans"),
                                 url("./assets/fonts/WorkSans/WorkSans-Light.ttf") format("truetype");
                                font-weight: 100;
                                }     
                                @font-face {
                                    font-family: "WorkSans";
                                    src: local("WorkSans"),
                                     url("./assets/fonts/WorkSans/WorkSans-Medium.ttf") format("truetype");
                                    font-weight: 500;
                                    }              
@font-face {
    font-family: "Campton";
    src: local("Campton"),
     url("./assets/fonts/Campton/CamptonBlack.otf") format("truetype");
    font-weight: 800;
    }
    @font-face {
        font-family: "Campton";
        src: local("Campton"),
         url("./assets/fonts/Campton/CamptonBlackItalic.otf") format("truetype");
        font-weight: 800;
        font-style: italic;
        }
        @font-face {
            font-family: "Campton";
            src: local("Campton"),
             url("./assets/fonts/Campton/CamptonExtraBold.otf") format("truetype");
            font-weight: 700;
            }
            @font-face {
                font-family: "Campton";
                src: local("Campton"),
                 url("./assets/fonts/Campton/CamptonExtraBoldItalic.otf") format("truetype");
                font-weight: 700;
                font-style: italic;
                }
                @font-face {
                    font-family: "Campton";
                    src: local("Campton"),
                     url("./assets/fonts/Campton/CamptonBold.otf") format("truetype");
                    font-weight: 600;
                    }
                    @font-face {
                        font-family: "Campton";
                        src: local("Campton"),
                         url("./assets/fonts/Campton/CamptonBoldItalic.otf") format("truetype");
                        font-weight: 600;
                        font-style: italic;
                        }
                        @font-face {
                            font-family: "Campton";
                            src: local("Campton"),
                             url("./assets/fonts/Campton/CamptonMedium.otf") format("truetype");
                            font-weight: 500;
                            }
                            @font-face {
                                font-family: "Campton";
                                src: local("Campton"),
                                 url("./assets/fonts/Campton/CamptonMediumItalic.otf") format("truetype");
                                font-weight: 500;
                                font-style: italic;
                                }
                                @font-face {
                                    font-family: "Campton";
                                    src: local("Campton"),
                                     url("./assets/fonts/Campton/CamptonBook.otf") format("truetype");
                                    font-weight: 400;
                                    }
                                    @font-face {
                                        font-family: "Campton";
                                        src: local("Campton"),
                                         url("./assets/fonts/Campton/CamptonBookItalic.otf") format("truetype");
                                        font-weight: 400;
                                        font-style: italic;
                                        }
                                        @font-face {
                                            font-family: "Campton";
                                            src: local("Campton"),
                                             url("./assets/fonts/Campton/CamptonLight.otf") format("truetype");
                                            font-weight: 300;
                                            }
                                            @font-face {
                                                font-family: "Campton";
                                                src: local("Campton"),
                                                 url("./assets/fonts/Campton/CamptonLightItalic.otf") format("truetype");
                                                font-weight: 300;
                                                font-style: italic;
                                                }
                                                @font-face {
                                                    font-family: "Campton";
                                                    src: local("Campton"),
                                                     url("./assets/fonts/Campton/CamptonExtraLight.otf") format("truetype");
                                                    font-weight: 200;
                                                    }
                                                    @font-face {
                                                        font-family: "Campton";
                                                        src: local("Campton"),
                                                         url("./assets/fonts/Campton/CamptonExtraLightItalic.otf") format("truetype");
                                                        font-weight: 200;
                                                        font-style: italic;
                                                        }
                                                        @font-face {
                                                            font-family: "Campton";
                                                            src: local("Campton"),
                                                             url("./assets/fonts/Campton/CamptonThin.otf") format("truetype");
                                                            font-weight: 100;
                                                            }
                                                            @font-face {
                                                                font-family: "Campton";
                                                                src: local("Campton"),
                                                                 url("./assets/fonts/Campton/CamptonThinItalic.otf") format("truetype");
                                                                font-weight: 100;
                                                                font-style: italic;
                                                                }
/* the following css is to override inline class of some components  */
/* .MuiCalendarPicker-root.css-169iwlq-MuiCalendarPicker-root {
    background-color: white;
    color:black;
  }
  .MuiPickersCalendarHeader-root.css-nk89i7-MuiPickersCalendarHeader-root {
    background-color: #dea449;
  }
  
  .MuiPickersCalendarHeader-label.css-dplwbx-MuiPickersCalendarHeader-label {
    color: white;
  }
  .MuiPickersCalendarHeader-root.css-nk89i7-MuiPickersCalendarHeader-root {
    margin-top: 1px !important;
  }
  .MuiDayPicker-header.css-qklzlb-MuiDayPicker-header > span {
    color: black;
  }
  .MuiTypography-root.MuiTypography-caption.MuiDayPicker-weekDayLabel.css-14tlbt-MuiTypography-root-MuiDayPicker-weekDayLabel {
    color: black;
  }
  button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.css-1vzaesv-MuiButtonBase-root-MuiPickersDay-root {
    color: black;
    background-color: white;
  }
  button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.css-1vzaesv-MuiButtonBase-root-MuiPickersDay-root:hover {
    background-color: #dea449;
  }
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.css-1k3ibsw-MuiButtonBase-root-MuiIconButton-root
    > svg {
    color: #dea449;
  }
  button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today.css-1oqqgyh-MuiButtonBase-root-MuiPickersDay-root {
    background-color: #dea449;
    color: black;
  }
  button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today.css-1oqqgyh-MuiButtonBase-root-MuiPickersDay-root:hover {
    background-color: #d49d44;
    color: black;
  } */
  /* drop down style  */
  /* .MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list{
  background-color:white;
  }
  .MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list>li{
  background-color:white;
  color: black;
  }
  .MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list>li:hover{
  background-color:#dea449;
  color: black;
  } */
  /* Active stepper  */
  /* span.css-3c2fr9-MuiStepLabel-label.Mui-active{
  color:black;
  }
  span.MuiStepLabel-label.Mui-completed.css-3c2fr9-MuiStepLabel-label{
     color: black;
  }
  span.MuiStepLabel-label.Mui-disabled.css-3c2fr9-MuiStepLabel-label{
  color:#333333;
  } */
  /* div.MuiStepConnector-root.MuiStepConnector-horizontal.Mui-disabled.css-j5w0w9-MuiStepConnector-root{
    display:none;
  }
  div.MuiStepConnector-root.MuiStepConnector-horizontal.Mui-active.css-j5w0w9-MuiStepConnector-root{
    display:none;
  }
  div.MuiStepConnector-root.MuiStepConnector-horizontal.Mui-completed.css-j5w0w9-MuiStepConnector-root{
   display:none;
  } */