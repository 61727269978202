.custom-wrapper {
  width: 100% !important;
  display: block !important;
  margin-bottom: 25px !important;
  background-color:#ffffff
}

.custom-editor {
  height: 200px !important;
  border: 5px solid #ffffff !important;
  padding: 5px !important;
  border-radius: 2px !important;
  background-color:#ffffff
}

.custom-editor-terms {
  height: 400px !important;
  border: 5px solid #ffffff !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.custom-viewer {
  padding: 0 4em !important;
  font-size: larger;
  background-color:#ffffff
}
